import React, { useMemo, useState, useEffect } from 'react';
import { Button, Divider, message, Spin } from 'antd';
import { FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useAsyncCallback } from 'react-async-hook';
import classNames from 'classnames';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Actions from '../../Actions';
import Section from 'components/Section';
import ChildInfoTab from './ChildInfoTab';
import { AsyncSelect } from 'components/Select';
import { ADD_FAMILY_STEPS } from 'constants/index';
import familyService from 'services/family.service';
import { getFullName } from 'utils';
import { selectors } from 'features/family';
import { BiPlus } from 'react-icons/bi';
import ChildCreation from './ChildCreation';

const CREATE_NEW_ID = '__cc__app__entity__create-new__';

export default function ChildrenInfo({
  id,
  application,
  next,
  setStep,
  actions,
  dispatch,
  tab = '0',
  openSteps,
  onCancel,
  household,
  ...rest
}) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const isLoading = useSelector(selectors.selectLoading);

  const sponsorId = application?.applicant?.id;

  // const [familyRelationData, setFamilyRelationData] = useState({});
  const [newChildIds, setNewChildIds] = useState([]);
  // Stores parent info based on the __id__ of the parent
  const [newChildrenForm] = Form.useForm();

  // useEffect(() => {
  //   if (application?.id) {
  //     form.setFieldsValue({
  //       documents: {
  //         ...(documents || {}),
  //         OTHER: documents?.OTHER?.filter?.((doc) => doc.subEntityType === ENTITY_TYPES.CHILD) || [],
  //       },
  //     });
  //   }
  // }, [application?.id, documents, form]);

  const commonProps = useMemo(
    () => ({
      id,
      application,
      next,
      setStep,
      actions,
      dispatch,
      tab,
      openSteps,
      parentForm: form,
      household,
      ...rest,
    }),
    [id, application, next, setStep, actions, dispatch, tab, openSteps, form, household, rest],
  );

  useEffect(() => {
    /*
     * Get family relation data for all the new parents
     * And populate the antd Form with the data
     */
    const getFamilyRelationData = async (_newChildIds) => {
      const _familyRelationData = {};
      _newChildIds.forEach(async (_childId) => {
        const _relationshipData = await familyService.getFamilyRelation(household?.id, _childId);
        const _relationshipToSponsor = _relationshipData.find(
          (_relationshipData) => _relationshipData?.relatedPerson?.id === sponsorId,
        );

        if (_relationshipToSponsor) {
          _familyRelationData[_childId] = _relationshipToSponsor;

          newChildrenForm.setFieldValue([_childId, 'relationshipType'], _relationshipToSponsor?.relationshipType);
        }
      });

      // setFamilyRelationData(_familyRelationData);
    };

    if (newChildIds.length === 0 && Array.isArray(application?.children) && application?.children?.length !== 0) {
      const _newChildIds = application?.children?.map((_child) => _child.id);
      setNewChildIds(_newChildIds);

      getFamilyRelationData(_newChildIds);
    }
  }, [
    application?.children,
    application?.applicant?.id,
    application?.applicant?.singleParent,
    household?.id,
    newChildrenForm,
    newChildIds,
    sponsorId,
  ]);

  // const tabs = useMemo(() => {
  //   const tabs = Array.from(application?.children ?? []).map((child, index) => ({
  //     label: getFullName(child) || `Child ${index + 1}`,
  //     key: `${index}`,
  //     content: (
  //       <ChildInfoTab
  //         {...{
  //           ...rest,
  //           id,
  //           application,
  //           next,
  //           setStep,
  //           actions,
  //           dispatch,
  //           tab,
  //           openSteps,
  //           index,
  //           child,
  //           parentForm: form,
  //           household,
  //         }}
  //       />
  //     ),
  //   }));
  //   if (tabs.length > 0) {
  //     return tabs.concat({
  //       label: 'Child Documents',
  //       key: ADD_FAMILY_TABS.DOCUMENTS,
  //       content: <Documents {...{ ...rest, id, application, next, setStep, actions, dispatch, tab, openSteps }} />,
  //     });
  //   }
  //   return tabs;
  // }, [application, rest, id, next, setStep, actions, dispatch, tab, openSteps, form, household]);

  // const disabledTabs = useFamilyDisabledTabs({ step: ADD_FAMILY_STEPS.CHILDREN, openSteps });

  const { execute: saveChildren } = useAsyncCallback(
    async (values) => {
      try {
        newChildIds.forEach(async (_childId) => {
          const _payload = values[_childId];
          if (_payload) {
            if (_isNewUnregisteredChild(_childId)) {
              const _registeredChild = await familyService.addUpdateFamilyChild(household?.id, null, [_payload]);
              const _registeredChildId = _registeredChild?.[0]?.id;

              await familyService.saveChild(id, { id: _registeredChildId });
              await familyService.addUpdateFamilyRelationship(household?.id, _registeredChildId, {
                // "typeOfMember": {
                //     "title": "Adult",
                //     "id": "adults"
                // },
                relatedPerson: {
                  id: sponsorId,
                },
                relationshipType: _payload.relationshipType,
                person: {
                  id: _registeredChildId,
                },
              });
            } else {
              await familyService.saveChild(id, { id: _childId });

              await familyService.addUpdateFamilyRelationship(household?.id, _childId, {
                relatedPerson: {
                  id: sponsorId,
                },
                relationshipType: _payload.relationshipType,
                person: {
                  id: _childId,
                },
              });
            }
          }
        });

        await new Promise((resolve) => setTimeout(resolve, 300));

        const newApplication = await familyService.getApplication(id);
        dispatch(actions.setApplication(newApplication));

        form.resetFields();
        newChildrenForm.resetFields();

        next({
          paths: {
            step: ADD_FAMILY_STEPS.CHILDREN,
            id: application.id,
          },
        });
      } catch (e) {
        message.error({
          content: 'Unable to add parent(s) to application.',
          duration: 5,
        });
      }
    },
    [actions, familyService, id, newChildIds],
  );

  // const { loading: assigningChild, execute: saveChildren } = useAsyncCallback(
  //   async (values) => {
  //     try {
  //       await familyService.saveChild(id, values.child);
  //       setVisible(false);
  //       const newApplication = produce(application, (draft) => {
  //         draft.children.push(values.child);
  //       });
  //       dispatch(actions.setApplication(newApplication));
  //     } catch (e) {
  //       message.error({
  //         content: 'Unable to add child to application.',
  //         duration: 5,
  //       });
  //     }
  //   },
  //   [application, dispatch, actions, id],
  // );

  const { execute: addChild } = useAsyncCallback(
    async (values) => {
      setNewChildIds((ids) => [...ids, values.child?.id]);

      setVisible(false);
    },
    [application, dispatch, actions, id],
  );

  return (
    <div>
      <Spin spinning={isLoading}>
        <div className={classNames('white-box', { 'mb-6': newChildIds.length !== 0 })}>
          <Form form={form} layout="vertical" className="border-0">
            <Section heading="CHILD(REN) INFORMATION" headingClassName="page-title" collapsible={false}>
              <p className="instructions mb-6">Select child(ren) for this application.</p>

              <Divider className="border-t-2 border-gray-200 my-6" />

              <div className="flex flex-col xl:flex-row xl:justify-between mb-5 items-center">
                <Form.Item
                  name="children"
                  rules={[
                    {
                      validator: async () => {
                        if (application?.children?.length < 1) {
                          throw new Error('Please add at least one child.');
                        }
                      },
                    },
                  ]}
                  className="m-0"
                >
                  <p>Please add all child(ren) attending child care within the program's age limits.</p>
                </Form.Item>

                <div className="w-full sm:w-52 xl:flex xl:items-end mt-6 xl:mt-0">
                  <Button
                    className="w-full flex items-center"
                    onClick={() => {
                      // dispatch(actions.addChild());
                      setVisible(true);
                    }}
                    icon={
                      <div className="w-4 h-4">
                        <FaPlus className="w-4 h-4 text-primary" />
                      </div>
                    }
                  >
                    Add Child
                  </Button>
                </div>
              </div>
            </Section>
          </Form>
        </div>

        {/* <Tabs
          items={tabs}
          activeKey={tab || '0'}
          onTabClick={(key) => setStep(id, ADD_FAMILY_STEPS.CHILDREN, key)}
          disabledTabs={disabledTabs}
          className="full-width-tabs bordered"
        /> */}

        {newChildIds.length !== 0 && (
          <Form layout="vertical" form={newChildrenForm} onFinish={saveChildren}>
            {newChildIds.map((_id, i) => (
              <div key={_id}>
                {!_isNewUnregisteredChild(_id) && (
                  <ChildInfoTab
                    {...{
                      ...rest,
                      id,
                      application,
                      next,
                      setStep,
                      actions,
                      dispatch,
                      tab,
                      openSteps,
                      index: i,
                      childIds: newChildIds,
                      childId: _id,
                      parentForm: form,
                      household,
                    }}
                  />
                )}

                {_isNewUnregisteredChild(_id) && (
                  <ChildCreation {...commonProps} childIds={newChildIds} childId={_id} index={i} />
                )}
              </div>
            ))}
          </Form>
        )}
      </Spin>

      <Modal width={750} visible={visible} setVisible={setVisible} destroyOnClose>
        <Form layout="vertical" onFinish={addChild}>
          <h3 className="block text-md font-semibold uppercase text-primary my-2">Application Child Selection</h3>
          <p className="instructions mb-5 ml-2">Select the Child that you wish to add to this Application.</p>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-x-8 mb-6">
            <Form.Item
              name={['child']}
              label="Select Child"
              rules={[{ required: true, message: 'Child is required.' }]}
            >
              <AsyncSelect
                name="child"
                placeholder="Select Child"
                ariaLabel="Select Child"
                apiPrefix="families"
                optionsApiUrl={`/households/${household?.id}/children`}
                optionFormatter={(opt) => {
                  const _uniqueId = `${CREATE_NEW_ID}${_generateRandomId()}`;

                  return [
                    {
                      icon: <BiPlus className="w-4 h-4 align-middle" />,
                      label: 'Create New',
                      id: _uniqueId,
                      value: _uniqueId,
                    },
                  ].concat(opt.filter((_opt) => !newChildIds.includes(_opt.id)));
                }}
                getOptionLabel={(opt) => {
                  return _isNewUnregisteredChild(opt?.id) ? opt.label : getFullName(opt);
                }}
                getOptionValue={(opt) => opt.id}
                isOptionDisabled={(opt) => application?.children?.some((item) => item.id === opt.id)}
              />
            </Form.Item>
          </div>

          <div className="actions flex">
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button
              data-testid="submit-btn"
              // loading={assigningChild}
              // disabled={assigningChild}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>

      {newChildIds.length !== 0 && (
        <Actions
          onSubmit={async () => {
            try {
              await newChildrenForm.validateFields();
              newChildrenForm.submit();
            } catch (error) {}
          }}
          onBack={() => {
            if ((application.additionalParents || []).length !== 0) {
              setStep(id, ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS);
            } else {
              setStep(id, ADD_FAMILY_STEPS.SECOND_PARENTS);
            }
          }}
          // // onClear={activeRole === ROLES.FAMILY ? null : () => form.resetFields()}
          // onCancel={onCancel}
          // testId={`${ADD_FAMILY_STEPS.SECOND_PARENTS}-${index}-${ADD_FAMILY_TABS.INFO}`}
          style={{ marginTop: '1rem' }}
        />
      )}
    </div>
  );
}

const _isNewUnregisteredChild = (childId) => `${childId || ''}`.includes(CREATE_NEW_ID);
function _generateRandomId() {
  let result = Math.floor(Math.random() * 9 + 1).toString();

  for (let i = 1; i < 10; i++) {
    result += Math.floor(Math.random() * 10).toString();
  }

  return parseInt(result);
}
