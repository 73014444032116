import { useState } from 'react';
import Button from 'components/Button';
import DetailsDrawer from 'components/DetailsDrawer';
import { TrashCan, UserProfile } from '@carbon/icons-react';

export default function EntityReview({ className, title, description, items, onDelete }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <div
        className={
          'border border-solid border-[rgba(0,0,0,0.1)] p-16 rounded-sm flex items-center' +
          (className ? ` ${className}` : '')
        }
        style={{
          background:
            'linear-gradient(145deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%)',
        }}
      >
        <div className="flex-grow">
          <h2 className="font-bold">{title}</h2>
          <h5>{description}</h5>
        </div>

        <div className="flex gap-4">
          {onDelete && (
            <Button
              className={`icon-btn alert remove-button bordered`}
              icon={<TrashCan className="w-5 h-5" />}
              onClick={onDelete}
              style={{ width: '40px', height: '40px' }}
            />
          )}

          <Button
            icon={<UserProfile className="w-4 h-4" />}
            onClick={() => {
              setShowDetails(true);
            }}
            style={{ height: '40px' }}
          >
            See profile
          </Button>
        </div>
      </div>

      <DetailsDrawer
        title={title}
        description={description}
        items={items}
        open={showDetails}
        setOpen={setShowDetails}
      />
    </>
  );
}
