import React, { useEffect } from 'react';
import { Divider, Input, InputNumber, Spin, Typography } from 'antd';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Form from 'components/Form';
import Actions from '../../Actions';
import Section from 'components/Section';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { selectors } from 'features/family';
import ScheduleTable from '../ChildrenInfo/ScheduleTable';
import ApplicationEntityReview from '../../__components/ApplicationEntityReview';
import Documents from '../ChildrenInfo/Documents';
import { AsyncSelect } from 'components/Select';
import AddressFields from 'components/AddressFields';

import useStartEndDates from 'hooks/useStartEndDates';
import SwitchField from 'components/Switch/SwitchField';
import { useAsyncCallback } from 'react-async-hook';
import { familyService } from 'services';

const StartEndDates = ({ form, values }) => {
  const [startDate, endDate] = useStartEndDates({
    form,
    values,
    startDateFieldProps: {
      rules: [{ required: false }],
    },
    endDateFieldProps: {
      rules: [{ required: false }],
    },
  });
  return (
    <>
      <div>{startDate}</div>
      <div>{endDate}</div>
    </>
  );
};

export default function ChildrenDocs({
  id,
  application,
  next,
  setStep,
  actions,
  dispatch,
  tab = '0',
  openSteps,
  onCancel,
  household,
  ...rest
}) {
  const [form] = Form.useForm();
  const values = Form.useWatch(null, form);

  const isLoading = useSelector(selectors.selectLoading);

  const { children } = application;

  const { execute: getEducation } = useAsyncCallback(async () => {
    try {
      const educationInfo = {};

      children.forEach(async (_child) => {
        const _educationData = await familyService.getSchoolInfo(_child.id);

        if ((_educationData?.terms || []).length !== 0) {
          _educationData['isEnrolledInPreKorAbove'] = true;
        }

        educationInfo[_child.id] = _educationData;
      });

      form.setFieldsValue(educationInfo);
      return educationInfo;
    } catch (error) {
      newrelic.noticeError(error);
    }
  }, [children, form]);

  const { execute: updateValues } = useAsyncCallback(async (values) => {
    try {
      Object.keys(values).forEach(async (_childId) => {
        // If child is enrolled in PreK or above
        if (
          values[_childId]?.['isEnrolledInPreKorAbove'] === true &&
          children.find((_child) => _child.id === _childId)
        ) {
          await familyService.addUpdateSchool(_childId, [values[_childId]]);
        }
      });

      getEducation();

      next({
        paths: {
          step: ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS,
          id,
        },
      });
    } catch (error) {
      newrelic.noticeError(error);
    }
  }, []);

  useEffect(() => {
    getEducation();
  }, [getEducation]);

  return (
    <div>
      <Spin spinning={isLoading}>
        <Form form={form}>
          {
            children?.map((_child, i) => {
              return (
                <div className={classNames('white-box pb-6', { 'mt-12': i !== 0 })}>
                  {/* {application?.status?.status?.title === 'INCOMPLETE' && (
              <Tooltip placement="top" title="Archive This Child">
                <Button
                  onClick={onDeleteChild}
                  className={`icon-btn alert remove-button bordered absolute top-3 right-5 !w-10 !h-10`}
                  icon={<BiTrash />}
                  aria-label="Archive This Child"
                  loading={deletingChild}
                  disabled={deletingChild}
                />
              </Tooltip>
            )} */}

                  <ApplicationEntityReview className="rounded-b-none" entity={_child} isChild />

                  <Section headingClassName="page-title" heading="Pre-K" collapsible={false}>
                    <Form.Item name={[_child.id, 'isEnrolledInPreKorAbove']} valuePropName="checked">
                      <SwitchField>Child is enrolled in PreK or above</SwitchField>
                    </Form.Item>

                    {values?.[_child.id]?.['isEnrolledInPreKorAbove'] === true && (
                      <>
                        <div className="grid grid-cols-2 gap-x-4 mt-12">
                          <Form.Item name={[_child.id, 'educationId']} hidden>
                            <InputNumber />
                          </Form.Item>

                          <div className="col-span-2">
                            <Form.Item name={[_child.id, 'name']}>
                              <Input placeholder="Institution Name" />
                            </Form.Item>
                          </div>

                          <Form.Item name={[_child.id, 'type']} label="Type of School">
                            <AsyncSelect
                              optionsApiUrl="/education-types"
                              placeholder="School Type"
                              ariaLabel="School Type"
                              name="type"
                              apiPrefix="families"
                            />
                          </Form.Item>

                          <div>
                            <Form.Item name={[_child.id, 'grade']} label="Grade">
                              <AsyncSelect
                                optionsApiUrl="/options/5"
                                placeholder="Grade"
                                ariaLabel="Grade"
                                name="grade"
                                apiPrefix="families"
                              />
                            </Form.Item>
                          </div>

                          <StartEndDates form={form} />
                        </div>

                        <AddressFields addressKey={[_child.id, 'address']} id="address" required={false} />
                      </>
                    )}
                  </Section>

                  <Section headingClassName="page-title" heading="Schedule of Care" collapsible={false}>
                    <ScheduleTable
                      applicationId={id}
                      childId={_child.id}
                      applicationValues={application}
                      index={i}
                      parentForm={form}
                    />
                  </Section>
                </div>
              );
            })

            // <Form layout="vertical" form={newChildrenForm} onFinish={saveChildren}>
            // </Form>
          }
        </Form>

        <Divider />

        <div className="pb-6 mt-12">
          <Typography.Title level={3} className="block !mb-8">
            Documents
          </Typography.Title>

          <Documents {...{ ...rest, id, application, next, setStep, actions, dispatch, tab, openSteps }} />
        </div>
      </Spin>

      <Actions
        onSubmit={updateValues}
        onBack={() => {
          setStep(id, ADD_FAMILY_STEPS.CHILDREN);
        }}
        style={{ marginTop: '1rem' }}
      />
    </div>
  );
}
